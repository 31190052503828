import { DetailedHTMLProps, InputHTMLAttributes, Ref, forwardRef } from 'react'

import styled from '@emotion/styled'

import { lightTheme } from '@/theme'

const StyledTextInput = styled.input<{ hideBorder: boolean; alignCenter: boolean }>`
  width: 100%;
  text-align: ${(props) => (props.alignCenter ? `center` : `left`)};
  font-family: ${lightTheme.typography.font.sim};
  font-size: ${lightTheme.typography.size.desktop.body3}px;
  line-height: 2;
  box-shadow: ${(props) => (props.hideBorder ? `none` : `0 1.5px 1px -1px ${lightTheme.colors.borderInput}`)};
  transition: ${(props) => (props.hideBorder ? `none` : `box-shadow 150ms ease-out`)};
  &:focus {
    box-shadow: ${(props) => (props.hideBorder ? `none` : `0 2px 1px -1px ${lightTheme.colors.black}`)};
  }
`

interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  hideBorder?: boolean
  alignCenter?: boolean
}
export const TextInput = forwardRef(
  ({ hideBorder, alignCenter, ...inputProps }: InputProps, ref: Ref<HTMLInputElement>) => {
    return <StyledTextInput hideBorder={hideBorder} alignCenter={alignCenter} ref={ref} {...inputProps} />
  }
)

TextInput.displayName = 'TextInput'

TextInput.defaultProps = {
  hideBorder: false,
  alignCenter: false
}
